import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllPages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/f/feedbacks',{ params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

     
    


  },
}
